import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import ServiceTitle from "../ServiceTitle";
import { ServicesData } from "./Services";

const SerivesCard: React.FC = () => {
  return (
    <Container id="services">
      <ServiceTitle title="Our Services" />
      <Row justify="center" gutter={[16, 16]}>
        {ServicesData.map((data, index) => (
          <Col
            xs={24}
            md={8}
            xl={8}
            xxl={8}
            key={index}
            onClick={() => {
              data.url === undefined
                ? console.log()
                : window?.open(data.url, "_blank")?.focus();
            }}
          >
            <div className="our-services settings">
              <img src={data.img} width="60px" alt="" /> <br />
              <br />
              <h3>{data.title} </h3>
              <p>{data.description} </p>
            </div>
          </Col>
        ))}
      </Row>
      <div className="spacer"></div>
    </Container>
  );
};

export default SerivesCard;

const Container = styled.div`
  margin: 0 12vw;
  display: block;
`;
