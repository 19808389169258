import axios from "axios";
import { useQuery } from "react-query";
import { baseUrl } from "../configs/constants";

import { InsuranceCompaniesModel } from "./insurance-company";

export const useGetInsuranceCompanies = () => {
  return useQuery("gariCompanies", async () => {
    return await axios
      .get<InsuranceCompaniesModel>(
        `${baseUrl}/insurance-companies?PageNumber=1&PageSize=40`
      )
      .then((res) => {
        return res.data;
      });
  });
};



