// import { Button } from "antd";
import * as S from "./jumboStyle";
import googleStore from "../../assets/images/google-play-badge.svg";
import applePlayStore from "../../assets/images/app-store-badge.svg";
import { Link } from "react-router-dom";
import { FC } from "react";
import { Button } from "antd";
interface jumboProps {
  Heading: string;
  description: string;
  img: string;
  hasBadge: boolean;
}
export const Jumbotron: FC<jumboProps> = (props) => {
  return (
    <>
      <S.Container>
        <S.HeadingContent className="heading-Content">
          <h1>{props.Heading}</h1>
          <S.Description>{props.description}</S.Description>

          {props.hasBadge ? (
            <S.BadgeContainer>
              <Link
                to={{
                  pathname:
                    "https://play.google.com/store/apps/details?id=hobbiton.gari_client_app",
                }}
                target="_blank"
              >
                {" "}
                <S.AppStoreBadge src={googleStore} />{" "}
              </Link>

              <Link
                to={{
                  pathname:
                    "https://apps.apple.com/zm/app/gari-motor-insurance/id1559875392",
                }}
                target="_blank"
              >
                <S.AppStoreBadge src={applePlayStore} />
              </Link>
            </S.BadgeContainer>
          ) : (
            ""
          )}

          <Button
            type="primary"
            shape="round"
            size={"large"}
            onClick={() => {
              window.open("https://app.joingari.com", "_blank")!.focus();
            }}
          >
            Get A Quote
          </Button>
        </S.HeadingContent>

        <S.Img src={props.img} alt="app  image" />
      </S.Container>
    </>
  );
};
