import { Route, Redirect, Switch } from "react-router-dom";
import { AboutUsPage } from "./pages/AboutUsPage";
import ClaimsPage from "./pages/ClaimsPage";
import { FAQ } from "./pages/FAQ";
import { Home } from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import {DeleteAccount} from "./pages/DeleteAccount";

interface Props {}

export const GariPages = (props: Props) => {
  return (
    <>
      <Switch>
        <Route exact path="/home">
          <Home />
        </Route>
        <Route exact path="/Aboutus">
          <AboutUsPage />
        </Route>
        <Route exact path="/claims">
          <ClaimsPage />
        </Route>
        <Route exact path="/privacy_policy">
          <PrivacyPolicy />
        </Route>
        <Route exact path="/terms_of_use">
          <TermsOfUse />
        </Route>
        <Route exact path="/FAQs">
          <FAQ />
        </Route>
        <Route exact path="/delete">
          <DeleteAccount/>
        </Route>
        <Redirect to="/home" />
      </Switch>
    </>
  );
};
