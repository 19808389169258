import claims from "../assets/images/claims.png";
import location from "../assets/images/claimLocation.png";
import home from "../assets/images/homeApp.png";

export interface IClaimSteps {
  image: string;
  heading: string;
  description: string;
  stepNumber: number;
}

export const claimsStepsData: IClaimSteps[] = [
  //   {
  //     image: vehicle,
  //     heading: "Add Your Vehicle",
  //     description:
  //       "Paying the right premium? Chat to Rose and find out instantly",
  //     stepNumber: 1,
  //   },
  {
    image: home,
    heading: "Go to the App",
    description:
      "on the app select claims and choose the police you would want to intimate claim for",
    stepNumber: 1,
  },
  {
    image: location,
    heading: "Location",
    description: "On the app provide us with the location of the accident",
    stepNumber: 2,
  },
  {
    image: claims,
    heading: "Then it’s our turn",
    description: "We’ll reach out to complete your claim.",
    stepNumber: 3,
  },
];
