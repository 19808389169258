import { Col, Row } from "antd";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/images/gari_logo.png";
import { useHistory } from "react-router-dom";

export const Footer = () => {
  const history = useHistory();

  return (
    <Container>
      <Content>
        <img
          src={Logo}
          height="100vh"
          alt="gari logo"
          onClick={() => history.push("/home")}
          style={{ cursor: "pointer" }}
        />
        <Row justify="center" gutter={12}>
          <Col xs={10} md={6}>
            <h2 style={{ color: "white" }}>Legal</h2>

            <p>
              {" "}
              <NavLink to="/terms_of_use">Terms of Use</NavLink>
            </p>
            <p>
              {" "}
              <NavLink to="/privacy_policy">Privacy policy</NavLink>
            </p>
          </Col>

          <Col xs={10} md={6}>
            <h2 style={{ color: "white" }}>Help Center</h2>
            <p>
              {" "}
              <NavLink to="/FAQs">FAQ</NavLink>{" "}
            </p>
          </Col>
          <Col xs={10} md={6}>
            <h2 style={{ color: "white" }}>Social</h2>
            <p>
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/GARI-Motor-Insurance-108863558148352"
              >
                Facebook
              </a>{" "}
            </p>
            <p>
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/garimotorinsurance?utm_medium=copy_link"
              >
                Instagram
              </a>{" "}
            </p>
            <p>
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/GariMotor
                "
              >
                Twitter
              </a>{" "}
            </p>
            <p>
              {" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/in/gari-motor-insurance-6a1795217/"
              >
                LinkedIn
              </a>
            </p>
          </Col>
          <Col xs={10} md={6}>
            <h2 style={{ color: "white" }}>Company</h2>
            <p>
              {" "}
              <NavLink to="/aboutUs">About Us</NavLink>{" "}
            </p>
          </Col>
        </Row>
      </Content>
      <div style={{ backgroundColor: "#eee", padding: "10px " }}>
        <Row justify="center">
          <span style={{ fontSize: "18px" }}>©</span>
          <div style={{ width: "3px" }}></div>
          <p>
            Copyright Gari Motor Insurance {new Date().getFullYear()}. All
            Rights Reserved. Powered by{" "}
          </p>{" "}
          <div style={{ width: "5px" }}></div>
          <p>
            {" "}
            <a
              href="https://hobbitontechnologies.com/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              Hobbiton Technologies
            </a>
          </p>
        </Row>
      </div>
    </Container>
  );
};
const Content = styled.div`
  /* position: absolute; */
  bottom: 0;
  padding: 2vw;
  color: wheat;
  width: 100%;
  background-color: #16221d;
`;
const Container = styled.footer`
  width: 100%;
  height: 400px;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.2em;
  }
`;
