import {Button, Form, FormProps, Input, notification} from "antd";
import React, {useState} from "react";
import axios from "axios";
import {baseUrl} from "../configs/constants";


export const DeleteAccount = () => {
    const [api, contextHolder] = notification.useNotification();
    const [isLoading,setLoading] =  useState(false)

    const onDelete: FormProps["onFinish"] = async (values) => {
        try {
            setLoading(true)
            await axios.delete(`${baseUrl}/users`, values);
            api.success({
                message: 'Account deleted successfully'
            })
        } catch (e) {
            api.error({
                message: e.message
            })
            setLoading(false)
        }
    };




    return <>
        {contextHolder}
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <h1>Delete Account</h1>
        </div>

        <Form
            name="basic"
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
            style={{maxWidth: 600, margin: "auto"}}
            initialValues={{remember: true}}
            onFinish={onDelete}
            autoComplete="off"
        >
            <Form.Item
                label="Email"
                name="email"
                rules={[{required: true, message: 'Please input your email address!'}]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                label="Password"
                name="password"
                rules={[{required: true, message: 'Please input your password!'}]}
            >
                <Input.Password/>
            </Form.Item>


            <Form.Item wrapperCol={{offset: 8, span: 16}}>
                <Button
                    loading={isLoading}
                    type="primary"
                    htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    </>
}