import { Card } from "antd";
import styled from "styled-components";
import { BsCheckCircle } from "react-icons/bs";
// import { HiOutlineEmojiSad } from "react-icons/hi";
import whyUSImg from "../assets/images/deal.svg";
import ServiceTitle from "./ServiceTitle";
const cardStyle = {
  borderRadius: "10px",

  boxShadow: "5px 8px 24px 5px rgba(134, 134, 134, 0.6)",
  marginTop: "20px",
};
export const GariAdvantage = () => {
  return (
    <>
      <br /> <br />
      <ServiceTitle title="why choose us" />
      <Container>
        {/* <Card
          title="Other Insurance company"
          headStyle={{
            backgroundColor: "#eee",
            color: "#04a49c",
            fontWeight: "bold",
            fontSize: "18px",
            borderRadius: "10px",
          }}
          bordered={false}
          style={cardStyle}
        >
          <Content>
            <HiOutlineEmojiSad color="grey" size={20} />{" "}
            <Text>100% paper work</Text>
          </Content>
          <Content>
            <HiOutlineEmojiSad color="grey" size={20} />{" "}
            <Text>Lengthy and unclear policy contract</Text>
          </Content>
          <Content>
            <HiOutlineEmojiSad color="grey" size={20} />{" "}
            <Text>Old Technology</Text>
          </Content>
          <Content>
            <HiOutlineEmojiSad color="grey" size={20} />{" "}
            <Text>Broken Processes</Text>
          </Content>
        </Card> */}
        <Card
          title="Gari"
          // className='card2'
          headStyle={{
            backgroundColor: "#eee",
            color: "#04a49c",
            fontWeight: "bold",
            fontSize: "18px",

            borderRadius: "10px",
          }}
          bordered={false}
          style={cardStyle}
        >
          <Img src={whyUSImg} />
          <Content>
            <BsCheckCircle color="#04a49c" size={20} />{" "}
            <Text>
              100% <span>Digital</span>{" "}
            </Text>
          </Content>
          <Content>
            <BsCheckCircle color="#04a49c" size={20} />{" "}
            <Text>
              Get <span>Instant </span>Quotes{" "}
            </Text>
          </Content>
          <Content>
            <BsCheckCircle color="#04a49c" size={20} />{" "}
            <Text>
              <span>Multiple</span> Payment Channels{" "}
            </Text>
          </Content>
          <Content>
            <BsCheckCircle color="#04a49c" size={20} />{" "}
            <Text>
              <span>Road Tax</span> Payments{" "}
            </Text>
          </Content>
          <Content>
            <BsCheckCircle color="#04a49c" size={20} />{" "}
            <Text>
              <span>Seemless </span> Claims Processing{" "}
            </Text>
          </Content>
        </Card>
      </Container>
    </>
  );
};
export const Container = styled.div`
  background-color: #04a49c;
  /* background-image: url('../assets/images/'); */
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 50px;
  clip-path: ellipse(81% 100% at 36.67% 0%);

  @media (max-width: 850px) {
    flex-direction: column-reverse;
    clip-path: ellipse(183% 100% at 51.33% 0%);
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: flex-start;
  margin: 20px 30px;
`;
export const Img = styled.img`
  width: 300px;
  margin-bottom: 20px;
`;
export const Text = styled.h3`
  color: grey;
  margin-left: 20px;
  span {
    color: #04a49c;
    font-weight: bold;
  }
`;
