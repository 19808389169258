import * as S from "../../styles/style";
import Logo from "../../assets/images/gari_logo.png";
import Burger from "./Burger";
import { useHistory } from "react-router-dom";

type Props = {
  children?: any;
};

export default function Navbar(props: Props) {
  const history = useHistory();
  return (
    <>
      {/* <S.Nav color="#fff0">
        <Link to="/home">
          {" "}
          <S.Logo
            src={Logo}
            alt="Eti Soluções"
            // onClick={() => history.push("/home")}
          />
        </Link>
      </S.Nav> */}

      <S.Logo
        src={Logo}
        alt="Eti Soluções"
        onClick={() => history.push("/home")}
      />

      <Burger />
      {props.children}
    </>
  );
}
