import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Google Sans';
    @import url('../src/index.css');
  }
  
  body {
    padding: 0px;
    margin: 0px;
    /* width: 100vw; */
    background-color: #eee5;
    overflow-y: auto;
    overflow-x: hidden;

    p{
      font-size: 1em;
 
  }

  @media screen and (max-width: 768px) {
  p {
    font-size: calc(1em + 6 * ((100vw - 320px) / 680));
  }
}

    h1{
      font-size: 3.5em;
      font-weight: bold;
      
  }

  .ant-card{
    border-radius: 10px;
    /* height: 100%; */
    /* width: 100%; */
    /* display: inline-flex; */
  }
 
  @media screen and (max-width: 320px) {
  h1 {
    font-size: calc(2em + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (max-width: 1000px) {
  h1 {
    font-size: 2em;
  }} 
  }
 




  .box {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.our-services {
   
  height: 100%;
    padding: 20px 20px;
    min-height: 198px;
    text-align: center;
    border-radius: 10px;
    background-color: #eee9;
    transition: all .2s ease-in-out;
    box-shadow: 0 0 8px 0 #eee6
}



.our-services:hover h4,
.our-services:hover p {
    color: #444}



.settings:hover {
    box-shadow: 0 0 25px 0 rgba(20, 27, 201, .05);
    cursor: pointer;
    background-color: #dddb;
    /* background-image: linear-gradient(-45deg, #34b5bf 0%, #210c59 100%) */
}






`;

export default GlobalStyle;
