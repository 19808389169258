import tax from "../../assets/icons/tax.svg";
import salvage from "../../assets/icons/salvage.svg";
import claims from "../../assets/icons/claims.svg";
import compare from "../../assets/icons/compare.svg";
import service_provider from "../../assets/icons/service_providers.svg";
import migrate from "../../assets/icons/migrate.svg";

export interface IServicesProps {
  title: String;
  description: String;
  img: string;
  url?: string;
}
export const ServicesData: IServicesProps[] = [
  {
    title: "Quote Comparison",
    description: "Compare Motor Quotations from top Insurance Companies",
    img: compare,
  },
  {
    title: "Get Road Tax",
    description: "Get your Road Tax through Gari",
    img: tax,
    url: "https://eservices.gov.zm/#/service/4 ",
  },
  {
    title: "Digital Claim Processing",
    description: "Process your Motor Insurance Claims through Gari",
    img: claims,
  },
  {
    title: "Migrate your Policy",
    description:
      "Go Digital. Migrate your existing Policy with any Insurance Company and get instant cover through Gari",
    img: migrate,
  },
  {
    title: "Service Providers",
    description:
      "Interact with Garages,Auto Spare Shops,Panel Beaters,Road Side Assistance through Gari",
    img: service_provider,
  },
  {
    title: "Salvages",
    description: "Buy your accident damaged vehicles through Gari",
    img: salvage,
  },
];
