import { Col } from "antd";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import { GariContentContainer } from "../components/GariContentContainer";

interface Props {}

const TermsOfUse = (props: Props) => {
  return (
    <GariContentContainer page="Gari terms of Use">
      <Col>
        <Title>Gari Motor Insurance Terms of Use</Title>

        <div>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Introduction.
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Hobbiton Technologies Limited is a Zambia owned dynamic FinTech
              Company with a strong focus on insurance platform provision,
              capital markets platform and digital aggregation services.Founded
              in 2017, Hobbiton Technologies Limited has the ability to tap into
              an experienced team of technologist, engineers and finance experts
              &nbsp;to deliver value to all stakeholders.Hobbiton has also
              developed an App,Web and/or USSD called GARI that is allowed to
              sell and administer Motor insurance products for the general
              insurance companies that are trading of the App,Web and/or USSD.
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              These terms of service ("the Terms") govern your ("You", "Your" or
              "User") access to and use of the GARI Insurance web, mobile
              App,Web and/or USSD lication ("the App,Web and/or USSD") and USSD,
              the website available at&nbsp;
            </span>
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#221f1f",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              www.joingari.com.
            </span>
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;("the Website") and the services associated with GARI
              Insurance App,Web and/or USSD that are provided by Us ("the
              Services") and are binding and enforceable against every person
              that accesses or uses the Website and/or App,Web and/or USSD
              and/or the Services. By accessing the Website and/or App,Web
              and/or USSD and/or the Services, You are explicitly acknowledging
              that you have read, understood and agree to be bound by these
              Terms.
            </span>
          </p>

          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SERVICES.
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Quotations.
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You may request a quotation for GARI insurance App,Web and/or USSD
              (a "Quotation") through the Website or App,Web and/or USSD. Any
              quotation generated by GARI is based on the information that You
              provide to Us together with information We secure from other
              sources and is neither insurance advice nor a contract to extend
              insurance coverage. You hereby warrant and represent that the
              information You provide Us with is true, accurate, complete and
              up-do-date
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              GARI insurance App,Web and/or USSD
            </span>
          </p>

          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  A binding contract for GARI insurance App,Web and/or USSD will
                  only be formed when You accept a Quotation and payment is
                  processed, and will be governed by the terms and conditions of
                  the Insurance Policy Provided by the General insurance
                  company.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Insurance Policy is a legal contract, under which General
                  Insurance Companies agrees to provide insurance cover, the
                  terms and conditions of which are available in our&nbsp;
                </span>
                <a
                  href="https://docs.naked.insure/Naked%2520Insurance%2520Basics.pdf"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "13.999999999999998pt",
                      // fontFamily: '"Helvetica Neue",sans-serif',
                      color: "#221f1f",
                      // backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    insurance basics document
                  </span>
                </a>
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  , as updated from time to time by various general insurance
                  companies.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Insurance Policy incorporates:
                </span>
              </p>
            </li>
          </ol>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the information You provide to Us when obtaining a Quotation
                  for GARI insurance App,Web and/or USSD, as well as any updates
                  that You make to this information via the App,Web and/or USSD
                  or through e-mail correspondence with Our helpdesk (
                </span>
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    color: "#221f1f",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  customerserves@gari.com.
                </span>
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  ), but only if you receive confirmation of receipt and the
                  change being App,Web and/or USSDlied, or through a call logged
                  with Our telephonic helpdesk; and
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  the latest details of Your GARI insurance App,Web and/or USSD
                  cover from GICs as shown on the App,Web and/or USSD.
                </span>
              </p>
            </li>
          </ul>
          <ol
            start={4}
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You must read the terms and conditions of the Motor Insurance
                  Policy carefully, as well as the latest details of Your GARI
                  insurance App,Web and/or USSD as set out on the App,Web and/or
                  USSD. It is Your responsibility to ensure that You understand
                  the scope of Your GARI insurance App,Web and/or USSD and cover
                  from the GICs, as well as Your rights and obligations under
                  the Insurance Policy. GARI is not a party to, and has no
                  obligations or liability under, the Insurance Policy.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If You do not comply with these Terms, the terms of the
                  Insurance Policy, or if the information You have provided to
                  Us is or becomes incorrect, incomplete or out of date, it may
                  result in Your claim being rejected or repudiated, and/or the
                  suspension or cancellation of Your Insurance Policy by the
                  Insurer concerned.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  To the extent that there is a conflict between these Terms and
                  the Insurance Policy, the Insurance Policy shall prevail.
                </span>
              </p>
            </li>
          </ol>

          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Your account
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You must register for and maintain an active personal user account
              ("Account") in order to access the Services on the App,Web and/or
              USSD, including:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  receiving a Quotation and/or purchasing Cover on the GARI
                  insurance platforms;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  viewing, updating or amending Your information;
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  viewing, updating or amending Your GARI insurance App,Web
                  and/or USSD cover; and/or
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Instituting a claim.
                </span>
              </p>
            </li>
          </ul>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You must be at least 18 years of age to obtain an Account. Account
              registration requires You to submit to GARI certain personal
              information. Please see Our Privacy Policy, available at&nbsp;
            </span>
            <a
              href="http://www.joingari.com/privacy-policy"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "13.999999999999998pt",
                  // fontFamily: '"Helvetica Neue",sans-serif',
                  color: "#0000ff",
                  // backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                www.joingari.com/privacy-policy
              </span>
            </a>
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;for additional information.
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You agree to maintain accurate, complete, and up-to-date
                  information in Your Account. Your failure to maintain
                  accurate, complete, and up-to-date information, including
                  having an invalid or expired payment method on file, may
                  result in Your inability to access the Services and could
                  affect Your Insurance Policy and any claims under it.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  As part of the Services or as prescribed in Your Insurance
                  Policy, You may be required to submit specific content to Us,
                  including photographs, cinematograph film, or other media
                  content ("User Content"). In submitting User Content to Us,
                  You grant Us a license to use the User Content as required by
                  Us to provide the Services.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You agree that we will store data relating to the details of
                  how You used our App,Web and/or USSD,USSD and/or Website, and
                  data sourced from external parties, and that such data may be
                  used by Us in the provision of the Services.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You are responsible for all activity that occurs under Your
                  Account, and You agree to maintain the security and secrecy of
                  Your Account username and password at all times. If you
                  suspect or become aware that Your Account may be accessed
                  without authorisation or a breach of security has occurred in
                  relation to Your Account, You must notify Us by e-mail
                  immediately by contacting customerservice@gari.com. If We
                  believe that any unauthorised access to Your Account has taken
                  or may take place, We may suspend or terminate Your access to
                  Your Account immediately and without prior notice. You agree,
                  however, that GARI is entitled to assume that any activity on
                  Your account or changes to your Insurance Policy(ies) have
                  been authorised by You.
                </span>
              </p>
            </li>
          </ul>

          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The App,Web and/or USSD
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You acknowledge that the App,Web and/or USSD is Your primary
                  interface with GARI in order to access:
                </span>
              </p>
            </li>
          </ol>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Your Account;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The details of Your GARI insurance App,Web and/or USSD and
                  Insurance cover/s; and
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Our Services;
                </span>
              </p>
            </li>
          </ul>
          <ol
            start={2}
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You accordingly agree to download the App,Web and/or USSD and
                  any updates thereto and use the App,Web and/or USSD as Your
                  primary interface with GARI.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  In the event that the App,Web and/or USSD is unavailable or
                  You are unable to access the App,Web and/or USSD for any
                  reason, please contact Us on e-mail for support in respect of
                  the above at&nbsp;
                </span>
                <a
                  href="mailto:help@naked.insure"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "13.999999999999998pt",
                      // fontFamily: '"Helvetica Neue",sans-serif',
                      color: "#221f1f",
                      // backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    customerservice@joingari.com&nbsp;
                  </span>
                </a>
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  .
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Certain functions of the App,Web and/or USSD will require an
                  active Internet connection, whether via a wireless or mobile
                  network. You acknowledge and agree that You are responsible
                  for obtaining the data network access necessary to access
                  and/or use the App,Web and/or USSD and for all fees associated
                  with such access and/or use.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The App,Web and/or USSD is currently available on Android and
                  iOS. You are responsible for acquiring and updating compatible
                  hardware or devices necessary to access and use the App,Web
                  and/or USSD and/or Services.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  GARI may wish to update the App,Web and/or USSD from time to
                  time and You agree to download any updates to the App,Web
                  and/or USSD that We make available to You.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Although We will use all reasonable efforts to ensure that the
                  App,Web and/or USSD operates in accordance with its
                  specifications, we do not warrant that the App,Web and/or
                  USSD, or any portion thereof, is compatible with, or will
                  operate with your device or any software and/or hardware that
                  you have on Your device. We make no warranty as to the
                  operation, availability or functionality of the App,Web and/or
                  USSD. You acknowledge that access to the Services may also be
                  subject to delays and malfunctions inherent in the use of the
                  Internet and electronic communications.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You acknowledge and agree that the binding version of Your
                  cover under any Insurance Policy is as displayed on the
                  App,Web and/or USSD at that point in time.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  By using the App,Web and/or USSD, You agree that all
                  notification and records, including Your Insurance Policy, may
                  be provided to You in electronic form.
                </span>
              </p>
            </li>
          </ol>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              License
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The Website and/or App,Web and/or USSD and/or Services are
                  made available solely for bona fide customers and must not be
                  used for any commercial purpose whatsoever, or for any illegal
                  or unauthorised purpose.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Subject to these Terms and Our policies (including policies
                  made available to you within the Services) We grant You a
                  limited, non-transferable, non-exclusive and revocable license
                  to access and use the Website and/or App,Web and/or USSD
                  and/or Services.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  By accessing the Website and/or App,Web and/or USSD and/or
                  Services, You agree that You will not (either yourself or
                  through any third party):
                </span>
              </p>
            </li>
          </ol>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  use any technology, robot, spider, screen scraper, data
                  aggregation tool or other automatic device or process
                  ("Automated Process") to process, monitor, copy or extract any
                  pages on the Website and/or App,Web and/or USSD or any of the
                  information, Content or data contained within or accessible
                  through the Website and/or App,Web and/or USSD, without Our
                  prior written permission;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  use any Automated Process to aggregate or combine information,
                  Content or data contained within or accessible through the
                  Website and/or App,Web and/or USSD with information, content
                  or data accessible via or sourced from any third party;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  use any information on or accessed through the Website and/or
                  App,Web and/or USSD for any commercial purpose (including but
                  not limited to market research, the provision of pricing
                  estimates or "shadow shopping") or otherwise (either directly
                  or indirectly) for profit or gain;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  use any device, software, process or routine to interfere or
                  attempt to interfere with the proper working of the Website
                  and/or App,Web and/or USSD or any transaction or process being
                  conducted on or through it;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  take any action that imposes an unreasonable or
                  disproportionately large load on the infrastructure of or
                  bandwidth connecting to the Website and/or App,Web and/or
                  USSD;
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  reverse engineer, reverse assemble, decompile, or otherwise
                  attempt to discover source code or other arithmetical formula
                  or processes in respect of the software underlying the
                  infrastructure and processes associated with the Services; and
                </span>
              </p>
            </li>
            <li
              aria-level={2}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "29pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  copy, reproduce, alter, modify, create derivative works, or
                  publicly display, any part of any content from the Website
                  and/or App,Web and/or USSD without Our prior written
                  permission.
                </span>
              </p>
            </li>
          </ul>
          <ol
            start={4}
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
          >
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  This license shall automatically and immediately, without
                  notice, terminate if You violate any of the restrictions in
                  section 3.3 above. You hereby defend, indemnify and hold
                  harmless GARI, our affiliates and our and their respective
                  owners, directors, offices, employees agents and/or licensors
                  from and against all claims, damages, losses, liabilities,
                  costs and expense (including but not limited to legal fees and
                  any costs incurred in identifying a violation of these
                  restrictions) relating or connected to your violation of any
                  of these restrictions.
                </span>
              </p>
            </li>
          </ol>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Ownership and copyright
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The contents of the Website and/or App,Web and/or USSD,
                  including any material, information, data, software, icons,
                  text, graphics, lay-outs, images, sound clips, advertisements,
                  video clips, trade names, logos, trade-marks, designs and
                  service marks which are displayed on or incorporated in this
                  Website and/or App,Web and/or USSD ("Content") are protected
                  by law, including but not limited to copyright and trade mark
                  law. The Content is the property of GARI and/or licensors.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Nothing contained on this Website and/or App,Web and/or USSD
                  shall be read in a manner which allows users to acquire any
                  right, title or interest in or to the Website and/or App,Web
                  and/or USSD, or the Content thereon.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Any use, distribution or reproduction of the Content is
                  prohibited unless expressly authorised in terms of these
                  Terms, or otherwise provided for in law.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Where any of the Content has been licensed to GARI or belongs
                  to any third party, Your rights of use may be subject to
                  additional terms and conditions which that licensor or third
                  party imposes from time to time, and You agree to comply with
                  such third party terms and conditions.
                </span>
              </p>
            </li>
          </ol>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Cookies
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Website and App,Web and/or USSD make use of Cookies in order
              to provide You with relevant content and the best experience
              possible whilst using the Website and/or App,Web and/or USSD. At
              any stage during Your use of the Website and/or App,Web and/or
              USSD You may choose to block the Cookies used by GARI, however
              this may impact your use or experience of the Website and/or
              App,Web and/or USSD.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#000",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Linking to third party websites
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The Website and/ or App,Web and/or USSD may contain links or
              references to other websites ("Third Party Websites") which are
              outside of Our control, including those of advertisers and
              sponsors. These Terms do not App,Web and/or USSDly to those Third
              Party Websites, and GARI is not responsible for Your use of or
              access to those Third Party Websites, or the practices and/or
              privacy policies of those Third Party Websites.
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              All use of Third Party Websites is entirely at Your own risk and
              We shall not be responsible for any loss, expense, claim or
              damages, whether direct, indirect or consequential, arising from
              Your use of such Third Party Websites or Your reliance on any
              information contained in or accessed through Third Party Websites.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#000",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Disclaimer
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              While We have taken reasonable measures to ensure that the Content
              is accurate, to the maximum extent permitted by law, the Website,
              App,Web and/or USSD and Services are provided on an 'as is' basis
              and You expressly understand and agree that your use of the
              Website and/or App,Web and/or USSD and/or Services is entirely at
              Your own risk.
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To the maximum extent permitted by law, We disclaim all
              representations and warranties (whether express, implied, arising
              under statute or otherwise) including any warranties of
              merchantability and fitness for a particular purpose and
              non-infringement of title or any third party rights.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Limitation of liability
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              To the maximum extent permitted by law, We shall not be liable for
              any direct, indirect, incidental, special or consequential loss or
              damages (including, without limitation, damages for loss of data)
              which might arise from Your use of, or reliance upon, the Website
              and/or App,Web and/or USSD and/or Services; or your inability to
              use the Website and/or App,Web and/or USSD and/or Services, and/or
              unlawful activity on the Website and/or App,Web and/or USSD and/or
              Services, or any linked Third Party Website.
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              You hereby indemnify Us against any loss, claim or damages which
              may be suffered by You or any third party arising in any way from
              Your use of, or reliance on, this Website and/or App,Web and/or
              USSD and/or Services, or any linked third party website.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Availability and termination
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              We may, in Our sole discretion, at any time and for any reason and
              without prior written notice, suspend or terminate the operation
              of the Website and/or App,Web and/or USSD (in whole or in part) or
              Your right to access or use the Website and/or App,Web and/or
              USSD, including for scheduled and unscheduled maintenance of the
              Website and/or App,Web and/or USSD. The functionality of the
              Website and/or App,Web and/or USSD and the availability of certain
              Services through the Website and/or App,Web and/or USSD may be
              impacted by such suspension or termination. However, You may
              contact Us on e-mail at&nbsp;
            </span>
            <a
              href="mailto:help@naked.insure"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "13.999999999999998pt",
                  // fontFamily: '"Helvetica Neue",sans-serif',
                  color: "#221f1f",
                  // backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                customerservice@joingari.com&nbsp;
              </span>
            </a>
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;for support.
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              The above mentioned termination or suspension will not impact the
              validity of GARI insurance App,Web and/or USSD purchased by You
              prior to such termination or suspension of the Website and/or
              App,Web and/or USSD, which shall continue to be governed by Your
              Insurance Policy and any other App,Web and/or USSDlicable terms
              and policies associated with such GARI insurance App,Web and/or
              USSD.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Privacy
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              All personal information provided by You to GARI will be processed
              in accordance with Our privacy policy, available www.joingari.com
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Governing law and jurisdiction
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              These Terms and the relationship between You and GARI and/or any
              dispute arising from or in connection with these Terms shall be
              governed and interpreted in accordance with the laws of the
              Government Republic of Zambia and the exclusive jurisdiction of
              the courts of Zambia.
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Agreement with General Insurance Companies&nbsp;
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              By making payment and/or providing your payment details for future
              payment, You agree that Your GARI insurance App,Web and/or USSD
              policy will start and the car will be covered for the cover set
              out in the policy summary on the GARI insurance App,Web and/or
              USSD App,Web and/or USSD. You also agree that:
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You have read and fully understand and accept the terms and
                  conditions of the policy, which is available on the quotation
                  provided and on the GARI insurance App,Web and/or USSD App,Web
                  and/or USSD.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You are buying this policy freely and voluntarily.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You understand that insurance companies share insurance and
                  claims information with other insurers, industry bodies and
                  credit agencies to reduce fraud. You give Your permission to
                  General Insurance Companies to process and share personal and
                  claims information for this purpose. (Note that General
                  Insurance Companies will always safeguard your information and
                  treat it as confidential. General Insurance Companies will not
                  share your contact information with any other party for
                  marketing purposes.)
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  You understand that all General Insurance Companies are
                  members of the Insurance Association Zambia (IAZ). You give
                  Your permission to General Insurance Companies to submit
                  information about how You pay Your premiums to this
                  Association every month.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Notices
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              GARI information:
            </span>
          </p>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Full name: Hobbiton Technologies Ltd.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Telephone:0211232877
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Email Address :customerservice@joingari.com
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Plot 2287 Corner of Lagos &amp; Lubuto Road,Lusaka,Zambia.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              GARI may change the address set out above from time to time by
              updating these Terms.
            </span>
          </p>
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              All notices sent in terms of these Terms must be sent either by
              hand, courier or email for the attention of Hobbiton Technologies
              Limited: Key Individual and must be in English.
            </span>
          </p>
          {/* <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p> */}
          <p
          // dir="ltr"
          // style={{
          //   lineHeight: "1.2",
          //   textAlign: "justify",
          //   marginTop: "0pt",
          //   marginBottom: "0pt",
          // }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              General
            </span>
          </p>
          {/* <p>
            <br />
          </p> */}
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  GARI reserves the right to amend, change or modify these Terms
                  from time to time. Changes will be effective upon the posting
                  of the modification to Our Website and/or App,Web and/or USSD
                  and Your continued use of the Website and/or App,Web and/or
                  USSD and/or Services will be considered acceptance of these
                  changes.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  The headings of the clauses in these Terms are provided for
                  convenience only and will not affect the meaning or
                  interpretation of these Terms.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  GARI may cede, assign or otherwise transfer its rights and
                  obligations in terms of these Terms to any third party.
                  Affiliates [and partners] of GARI are intended, direct third
                  party beneficiaries of these Terms, and may enforce them
                  against You.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  If any term or condition contained herein is declared invalid,
                  the remaining Terms will remain in full force and effect.
                </span>
              </p>
            </li>
            <li
              aria-level={1}
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "13.999999999999998pt",
                // fontFamily: '"Helvetica Neue",sans-serif',
                // color: "#04a49c",
                // backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-7pt",
                paddingLeft: "7pt",
              }}
            >
              <p
              // dir="ltr"
              // style={{
              //   lineHeight: "1.2",
              //   textAlign: "justify",
              //   marginTop: "0pt",
              //   marginBottom: "0pt",
              // }}
              >
                <span
                  style={{
                    fontSize: "13.999999999999998pt",
                    // fontFamily: '"Helvetica Neue",sans-serif',
                    // color: "#04a49c",
                    // backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  GARI's failure to exercise or enforce any right or provision
                  of these Terms shall not constitute a waiver of such right or
                  provision unless expressly acknowledged and agreed to by GARI
                  in writing.
                </span>
              </p>
            </li>
          </ol>
        </div>
      </Col>
    </GariContentContainer>
  );
};

export default TermsOfUse;

const Title = styled.h2`
  text-align: center;
  font-weight: bold;
  font-size: 1.8em;
`;
// const TermsLIst = styled.ol`
//   h2 {
//     font-weight: bold;
//   }
// `;
// const Keyword = styled.span`
//   font-weight: bold;
//   color: #04a49c;
// `;
