import styled from "styled-components";

interface INav {
  open: boolean;
  href?: string;
}

export const StyledBurger = styled.div<INav>`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 15px;
  right: 20px;
  z-index: 20;
  display: none;

  @media (max-width: 850px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${(props) => (props.open ? "#000" : "#000")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    cursor: pointer;
    &:nth-child(1) {
      transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${(props) =>
        props.open ? "translateX(100%)" : "translateX(0)"};
      opacity: ${(props) => (props.open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${(props) => (props.open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const Nav = styled.nav`
  /* display: flex; */
  /* justify-content: space-between; */
  /* height: 15vh; */
  /* width: 100%; */
  background-color: ${(props) => props.color};
  /* align-items: center; */
  /* position: relative; */
  @media (max-width: 678px) {
    width: 100%;
  }
  span {
    font-size: 30px;
    @media only screen and (max-width: 600px) {
      font-size: 20px;
      :nth-child(2) {
        font-size: 16px !important;
        margin-top: 0px !important;
      }
    }
  }
`;

export const Ul = styled.ul<INav>`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  /* background-color: green; */
  position: absolute;
  width: 98%;
  top: 0;
  justify-content: flex-end;
  margin-top: 16px;
  align-items: center;
  font-size: 16px;
  height: 110px;
  margin-left: 20px;

  a {
    text-decoration: none;
    text-transform: none;
    transition: all 0.2s ease-in-out;
    color: grey;
    cursor: pointer;

    &:hover {
      color: #04a49c;
    }
  }
  /* div{
 display: flex;
 justify-content: space-between;
  } */
  li {
    margin: 10px 18px;
  }
  @media (max-width: 850px) {
    flex-flow: column nowrap;
    background-color: #fdfdfdfc;
    position: fixed;
    transform: ${(props) =>
      props.open ? "translateX(0)" : "translateX(100%)"};
    top: -16px;
    right: 0;
    width: 60vw;
    height: 100vh;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;
    z-index: 9;
    justify-content: normal;

    li {
      color: gray;
      margin-right: 34px;
      &:hover {
        color: #04a49c;
      }
    }
  }
`;

export const Logo = styled.img`
  margin: 20px 50px 20px 7%;
  width: 100px;
  height: 70px;
  z-index: 100;
  position: relative;

  /* object-fit: contain; */
  cursor: pointer;

  /* background-color: green; */
  @media (max-width: 1250px) {
    margin: 50px 50px 20px 5%;
  }
  @media (max-width: 768px) {
    margin: -10px 50px 20px 5%;
    width: 80px;
    height: 70px;
  }
`;

export const LogoUl = styled.img`
  margin: -5px 50px 20px 5%;
  display: none;

  @media (max-width: 768px) {
    display: flex;
    width: 80px;
    height: 70px;
    object-fit: contain;
  }
`;
export const Icon = styled.div`
  width: 100vw;
  height: calc(100vh - 112px);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 150px;
    height: 150px;
    pointer-events: none;
    object-fit: contain;
    @media (prefers-reduced-motion: no-preference) {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
