import {
  Container,
  Img,
  Heading,
  Description,
  ContentContainer,
} from "./garifunctionStyels";
import { IGariFn } from "./gFnData";



export const GariFunctions = (props: IGariFn) => {
  return (
<>
<Container isReversed={props.reserved} >
        <Img src={props.imgURL} />
      <ContentContainer>
        <Heading>{ props.heading}</Heading>
        <Description>
         {props.description}
        </Description>
      </ContentContainer>
      
    </Container>

    </>
  );
};
