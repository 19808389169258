interface IFAQData {
  heading: String;
  content: {
    Quesion: string;
    answer?: string;
    options?: string[];
    listAnswer?: string[];
  }[];
}

export const FAQData: IFAQData[] = [
  {
    heading: "FAQs Gari App",
    content: [
      {
        Quesion: "What is Gari?",
        answer: `Gari is an integrated motor insurance platform that allows clients to compare different quotes
          from different insurance companies and help them pick the best quote and proceed to purchase
          a motor policy. Gari is available of Mobile App, Web and USSD Version.`,
      },
      {
        Quesion: "How many types of covers are there under Motor insurance?",

        options: [
          "Comprehensive Insurance",
          " Motor Third Party",
          "Motor Third Party Fire and Theft",
          "Road Traffic Act Only",
        ],
        answer: `At Gari, we currently offer four plans under our motor private insurance solution namely;`,
      },
      {
        Quesion: "What vehicle types are covered under Gari",

        listAnswer: [
          "Sedan Cars",
          "Pickups",
          "Sport Utility Vehicles (SUV)",
          "Light Tracks",
          "Buses/Taxis",
          "Motor Bikes",
          "Trailers",
        ],
        answer: `You can insure the following vehicle types under Gari;`,
      },
      {
        Quesion:
          "What policy documents am I receiving after insuring with Gari?",

        options: [
          "Certificate of insurance",
          "Policy Schedule",
          "Policy wording",
          "Debit Note",

          "Receipt",
        ],
        answer: `You shall receive the following documents after insuring on Gari;`,
      },
      {
        Quesion: "How do I make payment for my Gari Policy?",

        options: [
          "Airtel Money",
          "MTN Mobile Money",
          "Zamtel Kwacha",
          "Visa/Mastercard",
        ],
        answer: `Gari will give the insuring public multiple payment options such as the following;`,
      },
      {
        Quesion: "How do I get my policy documents?",
        answer: `We don’t issue any physical policy document so immediately after purchasing a policy, you will
        receive an electronic version of the policy documents under your policy page on the App. All you
        need to do is confirm receipt and you are good to go.`,
      },
      {
        Quesion: "Can I get a quote for someone else?",
        answer: `No you cannot get quotations for anyone else.`,
      },
      {
        Quesion: "How do I update my personal information?",
        answer: `You can add or edit your personal details under your profile on the App and the changes will
        automatically be updated in our systems.`,
      },
      {
        Quesion: "Can I insure more than one vehicle?",
        answer: `You can insure as many vehicles as you can.`,
      },
      {
        Quesion: "How do I get to see the service providers?",
        answer: `All our partner service providers can be found on the App and at any time, you will be able to view all
        the services near you. These include Garages, Insurance companies, windscreen fitters.`,
      },
      {
        Quesion:
          "Can I change my current Third Party Insurance Cover to Comprehensive?",
        answer: `Yes, you can upgrade your cover from Third Party Motor Insurance to Comprehensive
        Insurance by simply paying additional premium and having your vehicle inspected by a partner
        insurer.`,
      },
      {
        Quesion: "Can I transfer the insurance cover when I sell my car?",
        answer: `No you cannot.`,
      },
      {
        Quesion: "Can I have extended limits on my benefits?",
        answer: `Yes, it is possible at request and payment of additional premium`,
      },
      {
        Quesion: "Can I make changes to my policy?",
        answer: `You can make changes to your running policy by submitting a request via the any of the Gari
        customer service channels`,
      },
      {
        Quesion: "Can I pause my insurance cover?",
        answer: `Yes you can pause your insurance cover`,
      },
      {
        Quesion: "Can I scale down my cover?",
        answer: `Yes, you can scale down on your cover from comprehensive to Third Party or Third Party Fire
        and Theft.`,
      },
      {
        Quesion: "Can I refer someone to Gari and what benefits do I get?",
        answer: `The referral section enables you to refer or invite anyone to access services on the Gari
        Platform. When the invited person purchases a policy, 5% of the premium will be collected and
        sent to the client’s Virtual Wallet on the platform`,
        listAnswer: [
          "Get money back for referring someone",
          "The money can be used at renewal or access various services on the Gari Platform",
        ],
      },
      {
        Quesion: "How do I renew my Gari Policy?",
        answer: `You can go to the policy renewal section on Gari and process your renewal transaction. Your
        documentation will be send to you in real time`,
      },
      {
        Quesion: "What do I do after I am involved in an accident?",
        answer: `Report the accident through Gari as well as to the nearest police station`,
      },
      {
        Quesion: "How do I make a claim?",
        answer: `You can file your claim through the App and it is easy, fast and efficient. Just answer a few
        questions and upload the necessary photos and documentation depending on the type of the
        claim. Once completed, you will receive a claim number and you can always track the progress
        of the claim through the App.`,
      },
      {
        Quesion:
          "What documentation do I need to have a fully documented claim?",
        listAnswer: [
          "Police Report",
          "Driver’s license",
          "White Book/Letter of Sale",
        ],
      },
      {
        Quesion: "How do I upload the document on Gari?",
        answer: `The documents can be uploaded from the claims section of Gari`,
      },
      {
        Quesion: "Where do I get a police report?",
        answer: `The police report can be obtained from the nearest police station`,
      },
      {
        Quesion: "How do I request for an inspection?",
        answer: `You can request for your damaged vehicle to be inspected via Gari.`,
      },
      {
        Quesion: "I forgot my log in details, what should I do?",
        answer: `Input your registered email address and click “forgot password”. We will then reset your password and
        after, you can change it to enable you log in back to the App.`,
      },
    ],
  },
];
