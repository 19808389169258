import React from "react";
import styled from "styled-components";
import { GariContentContainer } from "../components/GariContentContainer";

interface Props {}

const PrivacyPolicy: React.FC<Props> = () => {
  return (
    <>
      <GariContentContainer page="Privacy Policy">
        <PolicyContent>
          <br />
          <h2 className="head-title">Introduction</h2>
          <br />
          <p dir="ltr">
            We, Hobbiton Technologies Limited ("HTL/GARI" "We", "Us" and "Our")
            and our partners value and respect the importance of protecting
            personal information.
          </p>
          <p dir="ltr">
            This privacy policy ("this Policy") outlines which information We
            collect when you ("You", "Your") access or use Our mobile
            application ("the App"), the website available at{" "}
            <a href="https://www.naked.insure/">www.joingari.com</a> ("the
            Website") and the associated insurance products and services
            provided by Us ("Services"), and how We protect and use that
            information.
          </p>
          <p dir="ltr">
            By using and/or accessing the Website and/or App and/or Services You
            expressly consent to Us collecting and processing the personal
            information which You provide to Us. Any personal information that
            You give to Us will be treated as confidential and sensitive
            information.
          </p>
          <br />
          <h2 className="head-title">Personal information</h2>
          <p dir="ltr">
            For the purpose of this Policy, personal information is all
            information specific to You that is provided to Us by You, or
            otherwise received through, our Website, App, USSD, email, helpdesk
            or any other channel.
          </p>
          <p dir="ltr">This information may include Your:</p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">name and surname;</p>
            </li>
            <li dir="ltr">
              <p dir="ltr">identity number;</p>
            </li>
            <li dir="ltr">
              <p dir="ltr">email address;</p>
            </li>
            <li dir="ltr">
              <p dir="ltr">contact number;</p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                vehicle details, including details of its use and regular
                location;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                insurance history (details of previous cover and claims); and
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">password and username.</p>
            </li>
          </ul>
          <p dir="ltr">
            We may also collect and process data relating to Your visits to Our
            Website and/or App including, but not limited to, traffic data,
            location data, weblogs and other communication data.
          </p>
          <br />
          <h2 className="head-title">Disclosure of personal information</h2>
          <p dir="ltr">
            We will only disclose personal information to others where it is
            necessary to conduct Our business or is required by law, or as
            otherwise agreed with You. The instances when We would disclose the
            personal information obtained from You include:
          </p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                transmitting Your personal information to Our partners as
                required to process a quotation for and/or administer the
                Services;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                obtaining Your credit rating and scores from credit bureaus;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                obtaining or verifying Your information with the Road Transport
                and Safety Agency (RTSA);
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                as required by regulatory authorities or to comply with any
                regulation or legal process;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                where HTL/GARI, acting in good faith, is of the view that
                disclosure is necessary to protect or defend Our rights or
                property, or those of its partners, clients, agents, employees
                or third party service providers; or
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                where HTL/GARI undergoes a change in ownership, for the sole
                purpose of the new owner being able to continue operating the
                business
              </p>
            </li>
          </ul>
          <br />
          <h2 className="head-title">Data storage</h2>
          <p dir="ltr">
            HTL/GARI will ensure that it has in place and continues to maintain
            appropriate and reasonable precautions and measures in accordance
            with the requirements of applicable laws to protect Your personal
            information from being unlawfully accessed or processed by any third
            party. We will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Policy.
          </p>
          <p dir="ltr">
            You acknowledge and agree that the payment platforms are hosted by a
            Mobile money operators (MNOs) and our card operating partner DBO.
            and You hereby consent to Your personal information being
            transferred and disclosed to HTL/GARI"s billing platform provider,
            solely for purposes of processing transactions made through this
            Website and/or App and USSD.
          </p>
          <p dir="ltr">
            All personal information which You provide to HTL/GARI will be
            stored on servers in Zambia or in other locations where it is
            adequately protected. Should HTL/GARI transfer Your personal
            information from one country to another country, We shall take
            reasonable and appropriate measures to protect such personal
            information.
          </p>
          <br />
          <h2 className="head-title">Cookies</h2>
          <p dir="ltr">
            The Website and App make use of Cookies in order to provide You with
            relevant content and the best experience possible whilst using the
            Website and/or App. At any stage during Your use of the Website
            and/or App You may choose to block the Cookies used by HTL/GARI,
            however this may impact your use or experience of the Website and/or
            App.
          </p>
          <br />
          <h2 className="head-title">Advertising</h2>
          <p dir="ltr">
            HTL/GARI will not sell your personal information to any party, or
            provide it to any party for their marketing/advertisements.
          </p>
          <p dir="ltr">
            Your personal information may be used by HTL/GARI to provide you
            with offers for our service which may be relevant to you. At any
            point you may choose to completely opt out of your information being
            used for this purpose.
          </p>
          <br />
          <br />
          <br />
          <h2 className="head-title">Electronic communications</h2>
          <p dir="ltr">
            When You visit the Website and/or App or send emails to Us, You
            consent to receiving communications from us electronically in
            accordance with this Policy.
          </p>
          <br />
          <h2 className="head-title">
            Revoking consent and/or request deletion of the user's data
          </h2>
          <p dir="ltr">
            If you request, we will delete or anonymise your personal data so
            that it no longer identifies you, unless we are legally allowed or
            required to maintain certain personal data, including situations
            such as the following:
          </p>
          <ul>
            <li dir="ltr">
              <p dir="ltr">
                If you have an active insurance contract, we have to maintain
                your details in order to administer said policy;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                If there is an unresolved issue relating to your account, such
                as an outstanding or an unresolved claim or dispute we will
                retain the necessary personal data until the issue is resolved;
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                Where we are required to retain the personal data for our legal,
                tax, audit, and accounting obligations, we will retain the
                necessary personal data for the period required by applicable
                law; and/or,
              </p>
            </li>
            <li dir="ltr">
              <p dir="ltr">
                Where necessary for our legitimate business interests such as
                fraud prevention or to maintain the security of our users.
              </p>
            </li>
          </ul>
          <br />
          <h2 className="head-title">General</h2>
          <p dir="ltr">
            We reserve the right to amend this Policy from time to time and You
            agree to review the terms of this Police whenever You visit the
            Website and/or use the App and USSD to check for any such
            amendments.
          </p>
          <p dir="ltr">
            Questions, comments and requests regarding this Policy should be
            addressed to{" "}
            <a href="mailto:help@naked.insure">customerservices@joingari.com</a>
            .
          </p>
          <p dir="ltr">Date of last update: 21/09/2021</p>
          <div>
            <br />
          </div>
        </PolicyContent>
      </GariContentContainer>
      ;
    </>
  );
};

export default PrivacyPolicy;

const PolicyContent = styled.div`
  .head-title {
    color: #04a49c;
  }
`;
// const TermsLIst = styled.ol`
//   h2 {
//     font-weight: bold;
//   }
// `;
// const Keyword = styled.span`
//   font-weight: bold;
//   color: #04a49c;
// `;
