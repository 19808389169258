import { GariAdvantage } from "../components/GariAdvantage";
import { GariFunctions } from "../components/GariFunctions.tsx/GariFunctions";
import { GariFnData } from "../components/GariFunctions.tsx/gFnData";
import { Jumbotron } from "../components/Jumbotron/Jumbotron";
import { GariPartners } from "../components/GariPartiners/GariPartners";
import SerivesCard from "../components/Services/SerivesCard";
// import { Card } from "antd";
import mobileApp from "../assets/images/jumbotron2.png";

import Covers from "../components/products/Covers";
import { ContactUs } from "./ContactUs";
import ClaimsSection from "../components/claims/claimsSection";
export const Home = () => {
  return (
    <div>
      <Jumbotron
        Heading={"Get Motor Insurance in Minutes"}
        description={`Simple, honest Motor Insurance at 
            game-changing low prices`}
        img={mobileApp}
        hasBadge={true}
      />
      <SerivesCard />
      <ClaimsSection />
      {/* <div style={{ height: "50px", width: "100vw" }}></div> */}
      {GariFnData.map((data) => (
        <GariFunctions
          reserved={data.reserved}
          heading={data.heading}
          imgURL={data.imgURL}
          description={data.description}
        />
      ))}{" "}
      <Covers />
      <GariAdvantage />
      <GariPartners />
      <ContactUs />
    </div>
  );
};
