import * as S from "../../styles/style";
import { NavHashLink } from "react-router-hash-link";
import Logo from "../../assets/images/gari_logo.png";
import { NavLink } from "react-router-dom";
import { useBurgerStatus } from "./Burger";

const naStyleActive = {
  padding: "0",
  color: "#04a49c",

  borderBottom: "4px solid #04a49c ",
};
const naStyle = {
  borderBottom: "4px solid #ffffff00 ",
};

function RightNav() {
  // const history = useHistory();
  const open = useBurgerStatus((state) => state.opened);
  const changeStatus = useBurgerStatus((state) => state.changeStatus);
  return (
    <>
      <S.Ul open={open}>
        <NavLink to="/" onClick={() => changeStatus(!open)}>
          {" "}
          <S.LogoUl src={Logo} alt={"Gustavo Scarpim"} />
        </NavLink>
        <NavLink
          to="/home"
          style={naStyle}
          activeStyle={naStyleActive}
          onClick={() => changeStatus(!open)}
        >
          <li>Home</li>
        </NavLink>
        <NavHashLink
          to="/home#services"
          exact
          style={naStyle}
          scroll={(el) => {
            changeStatus(!open);
            el.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          <li>Services</li>
        </NavHashLink>{" "}
        <NavHashLink
          to="/home#products"
          exact
          style={naStyle}
          scroll={(el) => {
            changeStatus(!open);
            el.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          <li>Insurance Products</li>
        </NavHashLink>
        <NavLink
          to="/claims"
          style={naStyle}
          onClick={() => changeStatus(!open)}
          activeStyle={naStyleActive}
        >
          <li>Claims</li>
        </NavLink>
        <NavLink
          to="/aboutus"
          style={naStyle}
          onClick={() => changeStatus(!open)}
          activeStyle={naStyleActive}
        >
          <li>About Us</li>
        </NavLink>
        <NavHashLink
          to="/home#contactUs"
          exact
          style={naStyle}
          scroll={(el) => {
            changeStatus(!open);
            el.scrollIntoView({ behavior: "smooth", block: "start" });
          }}
        >
          <li>Contact Us</li>
        </NavHashLink>
      </S.Ul>
    </>
  );
}

export default RightNav;
