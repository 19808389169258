import { Collapse } from "antd";

import { GariContentContainer } from "../components/GariContentContainer";
import { FAQData } from "../data/FAQData";

const { Panel } = Collapse;

export const FAQ = () => {
  return (
    <GariContentContainer page="Frequently Asked Questions">
      {FAQData.map((data, index) => (
        <>
          <h3>{data.heading}</h3>
          <Collapse
            bordered={false}
            // defaultActiveKey={0}
            expandIconPosition="right"
          >
            {data.content.map((val, i) => (
              <>
                <Panel
                  key={i}
                  header={
                    <h3
                      key={i}
                      style={{ color: "#04a49c", fontWeight: "bolder" }}
                    >
                      {val.Quesion}
                    </h3>
                  }
                >
                  <p>{val.answer}</p>
                  <p>
                    {val.options?.map((val, index) => (
                      <li key={index}>{val}</li>
                    ))}
                  </p>
                  <p>
                    <ol>
                      {val.listAnswer?.map((val, index) => (
                        <li key={index}>{val}</li>
                      ))}
                    </ol>
                  </p>
                </Panel>
              </>
            ))}
            <br />
          </Collapse>
        </>
      ))}
    </GariContentContainer>
  );
};
