import { AiFillMail, AiFillPhone } from "react-icons/ai";
import { IoLocationSharp } from "react-icons/io5";

import { Card, Col, Row } from "antd";
import ServiceTitle from "../components/ServiceTitle";
import styled from "styled-components";

export const ContactUs = () => {
  return (
    <>
      <ServiceTitle title="Contact Us" />
      <Container id="contactUs">
        <Iframe
          title="map"
          scrolling="yes"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2404.4812958114185!2d28.300822661880996!3d-15.409715608149689!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19408bc209d9159b%3A0x8b3c51333d6cf025!2sHobbiton%20Technologies%20Limited!5e0!3m2!1sen!2szm!4v1619419847954!5m2!1sen!2szm"
        />
        <Card>
          <Row justify="space-around" gutter={30}>
            <Col xs={16} md={8}>
              <IoLocationSharp color="#04a49c" size={30} />
              <h3 style={{ display: "flex", flexDirection: "column" }}>
                <span>Plot # 13 ,</span>
                <span>Lunsenfwa Road ,</span>
                <span>Kalundu,</span>
                <span>Lusaka</span>
              </h3>
            </Col>
            <Col xs={16} md={8}>
              <AiFillMail color="#04a49c" size={30} />
              <h3>
                {" "}
                <a href="mailto:insure@joingari.com"> insure@joingari.com</a>
              </h3>
            </Col>
            <Col xs={16} md={8}>
              <AiFillPhone color="#04a49c" size={30} />
              <h3>
                {" "}
                <a href="tel:+260-976-741-655">0976 741655</a>{" "}
              </h3>
              <h3>
                <a href="tel:+260-765-192-382">0765 192382</a>
              </h3>
              {/* <h3>
                <a href="tel:+260-211-098-723">0211098723</a>
              </h3> */}
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 5vw;
  @media (max-width: 850px) {
    flex-direction: column;
    margin: 0 5vw;
  }
  @media (max-width: 1024px) {
    margin: 0 5vw;
  }
  .ant-card {
    @media (min-width: 1440px) {
      width: 40vw;
    }
  }
`;

const Iframe = styled.iframe`
  height: 300px;
  border: none;
  /* margin: 0 8vw; */

  @media (min-width: 780px) {
    /* width: 50vw; */
  }
  @media (min-width: 1440px) {
    width: 60vw;
  }
`;
