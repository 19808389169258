import compare from "../../assets/images/compareStep.png";
import payment from "../../assets/images/paystep.png";
import cover from "../../assets/images/coverstep.png";

export interface ICoverSteps {
  image: string;
  heading: string;
  description: string;
  stepNumber: number;
}

export const claimsData: ICoverSteps[] = [
  //   {
  //     image: vehicle,
  //     heading: "Add Your Vehicle",
  //     description:
  //       "Paying the right premium? Chat to Rose and find out instantly",
  //     stepNumber: 1,
  //   },
  {
    image: cover,
    heading: "Select Cover",
    description: "Select your prefered Motor Insurane product ",
    stepNumber: 1,
  },
  {
    image: compare,
    heading: "Get Quotes",
    description: "Compare Motor Quotations from top Insurance Companies",
    stepNumber: 2,
  },
  {
    image: payment,
    heading: "Make Your Payments",
    description: "Pay your Premium using multiple payment channels",
    stepNumber: 3,
  },
];
