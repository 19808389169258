import styled from "styled-components";
interface IGFN {
  isReversed: boolean;
}
export const Container = styled.div<IGFN>`
  display: flex;
  margin: 20px;
  justify-content: center;
  flex-direction: ${(props) => (props.isReversed ? "row-reverse" : "row")};
  align-items: center;
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;
export const Img = styled.img`
  width: 37%;
  height: 25vw;
  margin: 10px;
  @media (max-width: 320px) {
    width: 100%;
    height: 75vw;
  }
  @media (max-width: 850px) {
    width: 80%;
    height: 50vw;
  }
`;
export const ContentContainer = styled.h1`
  display: block;
  width: 30vw;
  margin: 10px;
  @media (max-width: 320px) {
    width: 100%;
  }
  @media (max-width: 850px) {
    width: 80%;
  }
`;
export const Heading = styled.h1`
  font-size: 2rem;
`;
export const Description = styled.p`
  font-size: 1.2rem;
  font-weight: 200;
  color: grey;
`;
