import styled from "styled-components";
import mtn from "../../assets/images/mtn.png";
import zamtel from "../../assets/images/zamtel.png";
import airtel from "../../assets/images/airtel.png";
import uba from "../../assets/images/UBA-Logo.svg";
// import hollard from "../../assets/images/hollard-logo-1.png";
import ServiceTitle from "../ServiceTitle";
import dpo from "../../assets/images/dpo.svg";
// import african_gray from "../../assets/images/african_grey.png";
// import mayfair from "../../assets/images/mayfair.png";
// import meanhood from "../../assets/images/meanhood.png";
// import adelvis from "../../assets/images/adelvis.png";
// import { BallBeat } from "react-pure-loaders";
// import innovate from "../../assets/images/innovate.png";
// import savenda from "../../assets/images/savenda.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useGetInsuranceCompanies } from "../../data/insuranceCompanies.service";
export const GariPartners = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 4, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const {
    isLoading: loadingCompanies,
    data: companies,
    error,
  } = useGetInsuranceCompanies();
  return (
    <PartnerContainer>
      <ServiceTitle title="Our Partners" />
      {loadingCompanies ? (
        <>Loading Partners</>
      ) : error ? (
        <>There was an Error while fetching partners</>
      ) : (
        <div className="logos">
          <Carousel
            swipeable={true}
            draggable={true}
            // showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            // autoPlaySpeed={2000}
            keyBoardControl={true}
            customTransition="ease 3s"
            transitionDuration={4000}
            containerClass="carousel-container"
            // removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={props.deviceType}
            dotListClass="custom-dot-list-style"
            // itemClass="carousel-item-padding-10-px"
          >
            <img src={uba} alt="" />
            <img src={dpo} alt="" width="250px" />
            <img src={zamtel} alt="" />
            <img src={mtn} alt="" />
            <img src={airtel} alt="" />

            {companies?.data.map((company, index) => (
              <img src={company.logoUrl} alt="" />
            ))}
          </Carousel>
        </div>
      )}
    </PartnerContainer>
  );
};

const PartnerContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .logos {
    /* background-color: #236b44; */
    width: 100vw;
    height: 150px;

    @media (max-width: 470px) {
      height: 100px;
      padding: 0 auto;
    }
    img {
      height: 5vw;

      @media (max-width: 850px) {
        height: 9vw;
      }
      @media (max-width: 470px) {
        height: 14vw;
      }
    }
  }
`;
