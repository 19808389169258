import { Card, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
// import { GariContentContainer } from "../GariContentContainer";
// import { Heading } from "../GariFunctions.tsx/garifunctionStyels";
// import { HeadingContent } from "../Jumbotron/jumboStyle";
import { FaArrowAltCircleRight } from "react-icons/fa";
import ServiceTitle from "../ServiceTitle";
import { coversData, risksData } from "./data";

interface Props {}

const Covers: React.FC<Props> = () => {
  const [coverIndex, setCoverIndex] = React.useState<number>(10);
  return (
    <>
      <br />
      <br id="products" />
      <ServiceTitle title="Motor Insurance Covers" />

      <Row justify="center" gutter={{ xs: 20, md: 8 }}>
        {coversData.map((data, index) => (
          <Col xs={20} md={8} lg={5} key={index}>
            <Col>
              <CoverCard
                isActive={index === coverIndex}
                onClick={() => {
                  if (index === coverIndex) {
                    setCoverIndex(10);
                    console.log("selected index", coverIndex);
                  } else {
                    setCoverIndex(index);
                    console.log("selected index 2", coverIndex);
                  }
                }}
              >
                <div>
                  <img src={data.icon} alt=" " className="steps-img" />
                  <h3>{data.title}</h3>
                </div>
                <div className="more-btn">
                  Learn More <FaArrowAltCircleRight size={20} />
                </div>
              </CoverCard>
              {index !== coverIndex ? (
                ""
              ) : (
                <CoverDetails visible={false}>
                  <h2> {coversData[coverIndex].title}</h2>
                  <p>{coversData[coverIndex].desc}</p>
                  <h2> {coversData[coverIndex].whatsCovered?.title}</h2>
                  <h4>{coversData[coverIndex].whatsCovered?.subtitle}</h4>
                  <ol type="a">
                    {coversData[coverIndex].whatsCovered?.benefits.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ol>
                  <h4>
                    {coversData[coverIndex].extendedBenefits?.extendedTitle}
                  </h4>
                  <ol type="1">
                    {coversData[coverIndex].extendedBenefits?.benefits.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ol>
                  <h2> {coversData[coverIndex].whatsNotCovered?.title}</h2>
                  <h4>{coversData[coverIndex].whatsNotCovered?.subtitle}</h4>
                  <ol type="a">
                    {coversData[coverIndex].whatsNotCovered?.benefits.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ol>
                  <h2> {coversData[coverIndex].prizeBenefits?.title}</h2>
                  <h4>{coversData[coverIndex].whatsNotCovered?.subtitle}</h4>
                  <ul>
                    {coversData[coverIndex].prizeBenefits?.damage.map(
                      (item, index) => {
                        return <li key={index}>{item}</li>;
                      }
                    )}
                  </ul>
                </CoverDetails>
              )}
            </Col>
          </Col>
        ))}
      </Row>
      {coverIndex > coversData.length ? (
        ""
      ) : (
        <CoverDetails visible={true}>
          <h2> {coversData[coverIndex].title}</h2>
          <p>{coversData[coverIndex].desc}</p>
          <h2> {coversData[coverIndex].whatsCovered?.title}</h2>
          <h4>{coversData[coverIndex].whatsCovered?.subtitle}</h4>
          <ol type="a">
            {coversData[coverIndex].whatsCovered?.benefits.map(
              (item, index) => {
                return <li key={index}>{item}</li>;
              }
            )}
          </ol>
          <h4>{coversData[coverIndex].extendedBenefits?.extendedTitle}</h4>
          <ol type="1">
            {coversData[coverIndex].extendedBenefits?.benefits.map(
              (item, index) => {
                return <li key={index}>{item}</li>;
              }
            )}
          </ol>
          <h2> {coversData[coverIndex].whatsNotCovered?.title}</h2>
          <h4>{coversData[coverIndex].whatsNotCovered?.subtitle}</h4>
          <ol type="a">
            {coversData[coverIndex].whatsNotCovered?.benefits.map(
              (item, index) => {
                return <li key={index}>{item}</li>;
              }
            )}
          </ol>
          <h2> {coversData[coverIndex].prizeBenefits?.title}</h2>
          <h4>{coversData[coverIndex].whatsNotCovered?.subtitle}</h4>
          <ul>
            {coversData[coverIndex].prizeBenefits?.damage.map((item, index) => {
              return <li key={index}>{item}</li>;
            })}
          </ul>
        </CoverDetails>
      )}

      <ServiceTitle title="Vehicle Categories" />

      <div style={{ margin: "0 8vw" }}>
        <Row justify="center" gutter={20}>
          {risksData.map((data, index) => (
            <Col
              xs={{ span: 20 }}
              md={{ span: 8 }}
              lg={{ span: 4 }}
              key={index}
            >
              {/* <div style={{ margin: "10px" }}></div> */}

              <Card style={{ width: "100%", height: "80%" }}>
                <img src={data.icon} alt=" " width="50px" />
                <h4>{data.title}</h4>
                {/* <p>{data.desc}</p> */}
              </Card>
            </Col>
          ))}
        </Row>
      </div>
      {/* <Heading>Covers</Heading> */}
    </>
  );
};

export default Covers;

const CoverCard = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: 1px solid #3f3f3f;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  background-color: ${(props) => (props.isActive ? " #04a49c84" : "")};
  :hover {
    background-color: #04a49c84;
  }

  .more-btn {
    padding: 8px;
    width: 50%;
    border-radius: 8px;
    border: 1.4px solid #3f3f3f;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const CoverDetails = styled.div<{ visible: boolean }>`
  width: 83%;
  margin: 0 auto;
  display: ${(props) => (props.visible ? " block" : "none")};
  @media (max-width: 768px) {
    display: ${(props) => (props.visible ? " none" : "block")};
  }
`;
