import * as S from "../../styles/style";

import RightNav from "./RightNav";
import create from "zustand";

const Burger = () => {
  const open = useBurgerStatus((state) => state.opened);
  const changeStatus = useBurgerStatus((state) => state.changeStatus);
  return (
    <>
      <S.StyledBurger open={open} onClick={() => changeStatus(!open)}>
        <div />
        <div />
        <div />
      </S.StyledBurger>
      <RightNav />
    </>
  );
};
export default Burger;

export const useBurgerStatus = create<any>((set) => ({
  opened: false,
  changeStatus: (status: boolean) =>
    set(() => ({
      opened: status,
    })),
}));
