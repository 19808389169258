import { Card, Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import { claimsData } from "./claimsData";

interface Props {}

const ClaimsSection: React.FC<Props> = () => {
  return (
    <ClaimsContainER>
      <Row justify="center">
        <h1 style={{ color: "white" }}>Simple steps. Instant cover.</h1>
      </Row>
      <Row justify="center" gutter={20}>
        {claimsData.map((data, index) => (
          <Col lg={5} key={index}>
            <img src={data.image} alt="" />
            <Card>
              <h2>{data.stepNumber}</h2>
              <h2>{data.heading}</h2>
              <h3>{data.description}</h3>
            </Card>
          </Col>
        ))}
      </Row>
    </ClaimsContainER>
  );
};

export default ClaimsSection;
const ClaimsContainER = styled.div`
  position: relative;
  background-color: #04a49c;
  padding: 20px;
  /* .ant-card {
    height: 47%;
  } */
  h1 {
    color: #04a49c;
  }
  text-align: center;
  img {
    width: 14vw;
    height: 16vw;

    @media screen and (max-width: 991px) {
      width: 30vw;
      height: 50vh;
    }
    @media screen and (max-width: 870px) {
      width: 40vw;
      height: 60vh;
    }
    @media screen and (max-width: 768px) {
      width: 40vw;
      height: 55vh;
    }

    @media screen and (max-width: 525px) {
      width: 50vw;
      height: 40vh;
    }
    @media screen and (max-width: 320px) {
      width: 50vw;
      height: 35vh;
    }
    /* position: absolute; */
  }
`;
