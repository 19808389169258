import bus from "../../assets/icons/bus.png";
import car from "../../assets/icons/car1.png";
import motorbike from "../../assets/icons/motorbike.png";
import truck from "../../assets/icons/truck.png";
import collision from "../../assets/icons/collision.png";
import trailer from "../../assets/icons/trailer.svg";

export interface IProduct {
  title: string;
  desc: string;
  icon: string;
  price?: string;
  whatsCovered?: {
    title: string;
    subtitle: string;
    benefits: string[];
  };
  extendedBenefits?: {
    extendedTitle: string;
    benefits: string[];
  };
  whatsNotCovered?: {
    title: string;
    subtitle: string;
    benefits: string[];
  };
  prizeBenefits?: {
    title: string;
    damage: string[];
  };
}

export const coversData: IProduct[] = [
  {
    title: "Comprehensive",
    desc: "This is highest type of cover under the motor insurance, which in addition to Third party liabilities, and third-party fire and theft, it also protects you for any Loss or damage to your vehicle and its accessories and spare parts as a result of accident, theft fire and impact damages.  ",
    icon: collision,
    whatsCovered: {
      title: "What is covered?",
      subtitle: "The insurance company shall be liable for;",
      benefits: [
        " accidental damage – including RTA",
        "theft and or attempted theft",
        "Fire",
        " Impact damage as a result of flying object and falling trees",
        "vandalism",
        "natural events such as earthquake, flood, lightning and explosion.",
      ],
    },
    extendedBenefits: {
      benefits: [
        "bodily injury or death ",
        "damage to properties for the third party and",
        "legal expenses and cost relating to 1 and 2 ",
      ],
      extendedTitle:
        "The policy also protects you or your authorized driver against legal liability that may arise by use of the vehicle that may result in;",
    },
    whatsNotCovered: {
      title: "What is not covered?",
      subtitle: "The insurance company shall not be liable to pay for",
      benefits: [
        "consequential loss as a result of any cause whatsoever, depreciation in value whether arising from repairs following a defined event or otherwise, wear and tear, mechanical, electronic or electrical breakdowns, failures or breakages",
        "damage to tyres by application of brakes or by road punctures, cuts or bursts",
        "damage to springs/shock absorbers due to inequalities of the road or other surface or to impact with such inequalities",
        "detention, confiscation or requisition by customs or other officials or authorities.",
      ],
    },
    prizeBenefits: {
      title: "What are the default benefits of the Cover?",
      damage: [
        "Bodily injury and death - ZMW30, 000.00 Per Person and ZMW100, 000.00 Per event.",
        "Property Damage - ZMW30, 000.00",
        "Radio limit - up to ZMW1, 000.00",
        "Towing limit - up to ZMW1, 000.00",
      ],
    },
  },

  {
    title: "Third Party Fire and Theft ",
    desc: "Third party Fire and Theft cover, covers any Loss or damage to the insured vehicle and its accessories and spare parts as a result of theft or fire. ",
    icon: collision,
    whatsCovered: {
      title: "What is covered?",
      subtitle:
        "Third party fire and theft protect you against the following risks;",
      benefits: [
        "fire  ",
        " self-ignition",
        "lightning ",
        "explosion",
        "theft and or attempted theft",
      ],
    },
    whatsNotCovered: {
      title: "What is not covered under third party, Fire and Theft",
      subtitle: "The insurance company shall not be liable to pay for",
      benefits: [
        "Claims as fails within the scope of any compulsory motor vehicle insurance enactment. ",
        "death of or injury to any person being carried in or upon or entering or getting onto or alighting from the insured vehicle ",
        "liability arising from the operation, demonstration or use (for purposes other than maintenance or repair of the vehicle) of any tool or plant forming part of or attached to or used in connection with a vehicle or anything manufactured by or contained in any such tool or plant. ",
      ],
    },
    prizeBenefits: {
      title: "What are the default benefits of the Cover?",
      damage: [
        "Bodily injury and death - ZMW30, 000.00 Per Person and ZMW100, 000.00 Per event.",
        "Property Damage - ZMW30, 000.00",
        "Radio limit - up to ZMW1, 000.00",
        "Towing limit - up to ZMW1, 000.00",
      ],
    },
  },
  {
    title: "Third Party",
    desc: "Third party cover protects you against any accident caused by your insured vehicle which can cause you to be becomes legally liable to pay all sums including claimant's costs and expenses in respect of death of or bodily injury to any person and damage to property other than property belonging to the insured.",
    icon: collision,
    whatsCovered: {
      title: "What is covered?",
      subtitle: "The insurance company shall be liable for;",
      benefits: ["injury ", "death", "property damage"],
    },
    whatsNotCovered: {
      title: "What is not covered under third party",
      subtitle: "The insurance company shall not be liable in respect of",
      benefits: [
        "Claims as fails within the scope of any compulsory motor vehicle insurance enactment. ",
        "death of or injury to any person being carried in or upon or entering or getting onto or alighting from the insured vehicle ",
        "liability arising from the operation, demonstration or use (for purposes other than maintenance or repair of the vehicle) of any tool or plant forming part of or attached to or used in connection with a vehicle or anything manufactured by or contained in any such tool or plant. ",
      ],
    },
    prizeBenefits: {
      title: "What are the default benefits of the Cover?",
      damage: [
        "Bodily injury and death - ZMW30, 000.00 Per Person and ZMW100, 000.00 Per event.",
      ],
    },
  },
  {
    title: "Act Only Cover",
    desc: "Act only is the most basic cover under motor insurance that covers you against any accident caused by your vehicle of which you may become legally liable to pay all sums including claimant's costs and expenses in respect of death of or bodily injury to any person ",
    icon: collision,
    whatsCovered: {
      title: "What is covered?",
      subtitle:
        "The cover protects you against legal liability as a result of your use of the vehicle that may cause;",
      benefits: ["injury ", "death"],
    },
    whatsNotCovered: {
      title: "What is not covered under third party",
      subtitle: "The insurance company shall not be liable in respect of",
      benefits: [
        "Claims as fails within the scope of any compulsory motor vehicle insurance enactment. ",
        "death of or injury to any person being carried in or upon or entering or getting onto or alighting from the insured vehicle ",
        "liability arising from the operation, demonstration or use (for purposes other than maintenance or repair of the vehicle) of any tool or plant forming part of or attached to or used in connection with a vehicle or anything manufactured by or contained in any such tool or plant. ",
      ],
    },
    prizeBenefits: {
      title: "What are the default benefits of the Cover?",
      damage: [
        "Bodily injury and death - ZMW30, 000.00 Per Person and ZMW100, 000.00 Per event.",
      ],
    },
  },
];
export const risksData: IProduct[] = [
  {
    title: "Private Vehicles",
    desc: "",
    icon: car,
  },
  {
    title: "Commercial Vehicles",
    desc: "",
    icon: bus,
  },
  {
    title: "Buses and Taxies",
    desc: "",
    icon: car,
  },
  {
    title: "Motorbikes",
    desc: "",
    icon: motorbike,
  },
  {
    title: "Trucks",
    desc: "",
    icon: truck,
  },
  {
    title: "Trailers",
    desc: "",
    icon: trailer,
  },
];
