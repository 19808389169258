import React from "react";
import styled from "styled-components";

interface Props {
  title: String;
}

const ServiceTitle: React.FC<Props> = (props) => {
  return (
    <Container>
      {/* <Lines></Lines>

      <div>
        {" "}
        <h1>{props.title}</h1>
      </div>
      <Lines></Lines> */}
      <div className="hrtext">
        <div className="hrbefore">
          <hr />
        </div>
        <div className="hrcontent">
          <ServiceHeading> {props.title.toLocaleUpperCase()} </ServiceHeading>
        </div>
        <div className="hrafter">
          <hr />
        </div>
      </div>
    </Container>
  );
};

export default ServiceTitle;
const ServiceHeading = styled.h2`
  color: #04a49c;
  font-weight: 900;
  font-size: 2em;
`;
const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 20px;
  justify-content: center;
  align-items: baseline;
`;
