import { FC } from "react";
import "./App.less";
import { BrowserRouter as Router } from "react-router-dom";
import Navbar from "./components/NavBar/NavBar";
import { GariPages } from "./GariPages";
import { Footer } from "./components/GariFooter/Footer";
import ScrollToTop from "./ScrollTop";
const App: FC = () => (
  <>
    <Router>
      <ScrollToTop />
      <Navbar />
      <GariPages />
      <Footer />
    </Router>
  </>
);

export default App;
