import { GariContentContainer } from "../components/GariContentContainer";
import ourStory from "../assets/images/our_story.png";
import { Heading } from "../components/GariFunctions.tsx/garifunctionStyels";
import styled from "styled-components";

export const AboutUsPage = () => {
  return (
    <GariContentContainer page="About Us">
      <OpeninngRemarks>
        <div>
          <Heading>Our Story</Heading>

          <p>
            The Gari brand was conceived with one mission, to bring convenience
            and simplicity to how motor insurance is delivered to customers.Gari
            promises to make Motor Insurance more affordable, simple and
            convenient. Join the Gari revolution and experience superior
            service.
          </p>
        </div>
        <div className="img">
          <img src={ourStory} alt="our Story" />
        </div>
      </OpeninngRemarks>
      <div>
        <br />
        <p>
          Motor Insurance is the biggest class of insurance on the Zambian
          Market.Motor insurance policies have been sold via traditional
          channels until now.Hobbiton decided to develop the Gari Motor
          Insurance Platform to alleviate all pain points in the Motor Insurance
          delivery framework in Zambia.
        </p>
        <p>
          Gari enables customers to select quotes from top insurance companies
          and proceed to buy insurance from the Insurance Company of their
          choice.Gari brings the entire motor insurance value chain and
          supporting entities onto a single platform.Finally Motor Insurance in
          Zambia has got digital, paperless and mobile.Gari is available on
          Mobile App,Web and USSD.
        </p>
      </div>
      <br />
      <Heading>Vision</Heading>
      <p>A world class provider of innovative solutions.</p>
      <Heading>Mission</Heading>
      <p>
        To deliver innovative customer driven products and experiences across
        various technology platforms
      </p>
      <Heading>Values</Heading>

      <p>
        {" "}
        <span style={{ color: "black", fontWeight: "bolder" }}>
          {" "}
          Innovation
        </span>{" "}
        - Creativity and out of the box thinking to deliver the best quality
      </p>

      <p>
        {" "}
        <span style={{ color: "black", fontWeight: "bolder" }}>
          {" "}
          Excellence
        </span>{" "}
        - With Ingenuity, we shall deliver outstanding and world-class quality
        at all times
      </p>

      <p>
        {" "}
        <span style={{ color: "black", fontWeight: "bolder" }}>
          {" "}
          Integrity
        </span>{" "}
        - We shall be honest, consistent and trustworthy in our dealings
      </p>
      <p>
        {" "}
        <span style={{ color: "black", fontWeight: "bolder" }}>
          {" "}
          Team Work
        </span>{" "}
        - Together, we achieve more.
      </p>
    </GariContentContainer>
  );
};
const OpeninngRemarks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  img {
    width: 25vw;
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    flex-direction: column;
    .img {
      height: 25vw;
      width: 90vw;
      background-color: #4abac882;
      overflow: hidden;
    }
    img {
      width: 50vw;
    }
  }
`;
