import styled from "styled-components";
export const Img = styled.img`
  height: 35vw;
  @media (max-width: 850px) {
    height: 78vw;
  }
  /* height: 37vw; */
  /* @media (min-width: 768px) {
    height: 20vw;
  }
  @media (min-width: 1024px) {
    height: 5vw;
  } */
  /* @media (min-width: 768px) {
   
   height: 45vw;
 } */
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* width: 100vw; */
  /* padding: 0 5rem; */
  .ant-btn {
    width: 12rem;
    height: 50px;
  }

  @media (max-width: 850px) {
    /* width: 20vw; */
    flex-direction: column-reverse;
    .ant-btn {
      width: 8rem;
      height: 40px;
    }
  }
  @media (max-width: 320px) {
    /* flex-direction: column-reverse; */
  }
`;

export const AppStoreBadge = styled.img`
  height: 150px;

  margin: -20px 10px;
  cursor: pointer;
  @media (max-width: 768px) {
    height: 120px;
  }
`;
export const BadgeContainer = styled.div`
  display: flex;
  /* flex-wrap: nowrap; */
  /* background-color: green; */
  @media (max-width: 320px) {
    /* flex-direction: column; */
  }
`;

export const HeadingContent = styled.div`
  padding: 6% 30px;
  width: 30vw;
  @media (max-width: 850px) {
    width: 100vw;
  }
`;
export const Description = styled.h2`
  color: #04a49c;
`;
