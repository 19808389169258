import { useHistory } from "react-router-dom";

import { FiArrowLeftCircle } from "react-icons/fi";
import styled from "styled-components";
import { Heading } from "./GariFunctions.tsx/garifunctionStyels";
export const GariContentContainer = (props: any) => {
  let history = useHistory();

  return (
    <ContentContainer>
      <BackArrow>
        <FiArrowLeftCircle
          style={{ cursor: "pointer" }}
          size={45}
          color="grey"
          onClick={() => history.goBack()}
        />

        <Heading style={{ margin: "10px auto" }}>{props.page} </Heading>
        <div></div>
      </BackArrow>
      <div className="content">{props.children}</div>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  margin: 10px auto;
  width: 80vw;

  .content {
    height: max-content;
    margin-top: 30px;
    padding: 10%;

    box-shadow: 0px 1px 46px -23px rgba(105, 92, 92, 0.87);
    -webkit-box-shadow: 0px 1px 46px -23px rgba(105, 92, 92, 0.87);
    -moz-box-shadow: 0px 1px 46px -23px rgba(105, 92, 92, 0.87);
  }
  @media (max-width: 768px) {
    .content {
      padding: 20px;
    }
    width: 90vw;
  }
`;

const BackArrow = styled.div`
  display: flex;
  flex-direction: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
