import React from "react";
import { Jumbotron } from "../components/Jumbotron/Jumbotron";
import crash from "../assets/images/crash.svg";
import { Card, Col, Row } from "antd";
import { Heading } from "../components/GariFunctions.tsx/garifunctionStyels";
import { claimsStepsData } from "../data/ClaimStepsData";
import { RiCustomerService2Fill, RiSearchLine } from "react-icons/ri";
import { AiOutlineFileDone } from "react-icons/ai";
import styled from "styled-components";
const ClaimsPage: React.FC = () => {
  return (
    <div>
      <Jumbotron
        Heading={"File a Claim on the App"}
        description={`It only takes about 4 minutes`}
        img={crash}
        hasBadge={false}
      />

      <Row justify="center">
        <Col>
          <Heading style={{ margin: "0 12px" }}>How do I file a claim?</Heading>
        </Col>
      </Row>
      <br />
      <br />

      <Row justify="center" gutter={[24, 24]} style={{ margin: "0 12px" }}>
        {claimsStepsData.map((data, index) => {
          return (
            <Col
              md={8}
              style={{ textAlign: "center", width: "90%" }}
              key={index}
            >
              <Card>
                <h1>{data.stepNumber} </h1>

                <img src={data.image} alt="" width="200px" />
              </Card>
              <h2>{data.heading}</h2>
              <p style={{ textAlign: "center" }}> {data.description} </p>
            </Col>
          );
        })}
      </Row>
      <br />
      <br />
      <Row justify="center">
        <Heading>What’s next?</Heading>
      </Row>
      <br />
      <br />
      <Row
        justify="center"
        style={{ textAlign: "center", margin: "0 12px" }}
        gutter={[24, 24]}
      >
        <Col xs={24} md={8}>
          <RiCustomerService2Fill size={50} />
          <h2>We’ll contact you</h2>
          <p style={{ textAlign: "center" }}>
            We’ll call you to complete your claim.
          </p>
        </Col>
        <Col xs={24} md={8}>
          <RiSearchLine size={50} />
          <h2>We look at the damage</h2>
          <p style={{ textAlign: "center" }}>
            This helps us know how much money you need for repairs.
          </p>
        </Col>
        <Col xs={24} md={8}>
          <AiOutlineFileDone size={50} />
          <h2>We complete your claim</h2>
          <p style={{ textAlign: "center" }}>
            You may receive a check or electronic payment.
          </p>
        </Col>
      </Row>
    </div>
  );
};

export default ClaimsPage;
styled.img``;
