import easyAndFast from "../../assets/images/easy_and_fast2.png";
import getQuote from "../../assets/images/compare2.png";
import superFast from "../../assets/images/super_fast2.png";
import smartAAndFancy from "../../assets/images/deal.svg";

export interface IGariFn {
  heading: String;
  description: String;
  imgURL: string;
  reserved: boolean;
}

export const GariFnData: IGariFn[] = [
  {
    heading: `It all happens on the App`,
    description: `There’s no need to drive to any insurance Company . 
  Do everything on the App, Compare Quotes,Buy Policy,Premium Payments,
   Claim intimation,Salvage purchases, Road Tax Payment,
   Everything in the Palm of your Hand`,
    imgURL: easyAndFast,
    reserved: !true,
  },

  {
    heading: `Compare Quotes`,
    description: `Compare Quotes form top Insurance Companies in seconds. Happy ? convert Quote it into a Policy in a giffy`,
    imgURL: getQuote,
    reserved: true,
  },

  {
    heading: `Super fast claim settlement`,
    description: `Register Claim,Track Claim status,Claim Settlement and Claim Payment`,
    imgURL: superFast,
    reserved: false,
  },
  {
    heading: `Salvages`,
    description: `Go paperless with Salvage Biding on Gari, Bid for any listed Salvage on the Gari Platform`,
    imgURL: smartAAndFancy,
    reserved: true,
  },

  // {
  //   heading: `Pay less with a flexible cover that is tailored to your needs`,
  //   description: `Pick and adjust your period of cover. Choose short term or annual cover depending on your schedule and needs.`,
  //   imgURL: payLess,
  //   reserved: !false,
  // },
];
